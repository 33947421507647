<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">学员列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">导入报名学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px;">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalData.idcard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalData.mobile" type="text" size="small" placeholder="请输入手机号" clearable />
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalData.occupationId" placeholder="请选择培训工种" clearable size="small">
                <el-option v-for="item in occupationList" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                  <span style="float: left">{{ item.occupationName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.trainLevel }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px;">
            <div title="招生老师" class="searchboxItem ci-full">
              <span class="itemLabel">招生老师:</span>
              <el-select size="small" clearable v-model="retrievalData.channelId" placeholder="请选择招生老师">
                <el-option v-for="item in teacherIdList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId">
                </el-option>
              </el-select>
            </div>
            <div title="工作单位" class="searchboxItem ci-full">
              <span class="itemLabel">工作单位:</span>
              <el-input v-model="retrievalData.workUnit" type="text" size="small" placeholder="请输入工作单位" clearable />
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select size="small" clearable v-model="retrievalData.auditState" placeholder="请选择审核状态">
                <el-option v-for="item in auditStateData" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="报名时间" class="searchboxItem ci-full">
              <span class="itemLabel">报名时间:</span>
              <el-date-picker v-model="retrievalData.registrationTime" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="determineData">确定</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe @selection-change="handleSelectionChange"
              :row-key="(row) => row.projectApplyId">
              <el-table-column type="selection" :reserve-selection="true" width="55" fixed>
              </el-table-column>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="学员姓名" align="left" show-overflow-tooltip prop="userName" minWidth="100px" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="200px" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="160px" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" minWidth="150" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="trainLevel" minWidth="120">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.trainLevel) }}
                </template>
              </el-table-column>
              <el-table-column label="报名时间" align="left" show-overflow-tooltip minWidth="200px">
                <template slot-scope="scope">
                  {{
                    scope.row.createTime | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="工作单位" align="left" show-overflow-tooltip prop="workUnit" minWidth="200" />
              <el-table-column label="招生老师" align="left" show-overflow-tooltip prop="contactUser" minWidth="120" />
              <el-table-column label="审核状态" align="left" show-overflow-tooltip minWidth="120px">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_AUDIT_STATE", scope.row.auditState) }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "inkQuestionBank_modelExamScoreQuery_modelExamScoreQueryList",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 上个页面的数据
      lastPageData: {
        projectId: "", // 班级id
      },
      // 培训工种 - 下拉数据
      occupationList: [],
      // 招生老师 - 下拉数据
      teacherIdList: [],
      // 审核状态 - 下拉数据
      auditStateData: [],
      // 检索数据
      retrievalData: {
        userName: "", // 姓名
        idcard: "", // 身份证号
        mobile: "", // 手机号
        occupationId: "", // 培训工种
        channelId: "", // 招生老师
        workUnit: "", // 工作单位
        auditState: "", // 审核状态
        registrationTime: [], // 练习时间
      },
      // 列表多选数据
      multipleSelection: [],
    };
  },
  mixins: [List],
  created() {
    this.lastPageData.projectId = this.$route.query.projectId;
    this.searchCompOccupation();
    this.getCtChannelSelectList();
    this.getDictionary();
  },
  computed: {},
  methods: {
    // 获取字典
    getDictionary() {
      // 审核状态
      let arr1 = this.$setDictionary("CT_USER_AUDIT_STATE", "list");
      for (const key in arr1) {
        this.auditStateData.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 获取 - 招生老师
    getCtChannelSelectList() {
      this.$post("/biz/channel/list", {}, 3000, true, 6).then(res => {
        this.teacherIdList = res.data || [];
        this.teacherIdList.unshift({
          contactUser: "自招",
          channelId: "0",
        });
      },).catch(e => {
        console.error("获取招生渠道数据出错", e);
      });
    },
    // 获取 - 培训工种
    searchCompOccupation() {
      this.$post('/biz/ct/occupation/select/pageList', {
        pageNum: 1,
        pageSize: 10,
        occupationName: ""
      }).then(res => {
        this.occupationList = res.data || [];
      }).catch(err => {
        return;
      })
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        projectId: this.$route.query.projectId,
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.idcard) {
        params.idcard = this.retrievalData.idcard;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.occupationId) {
        params.occupationId = this.retrievalData.occupationId;
      }
      if (this.retrievalData.channelId) {
        params.channelId = this.retrievalData.channelId;
      }
      if (this.retrievalData.workUnit) {
        params.workUnit = this.retrievalData.workUnit;
      }
      if (this.retrievalData.auditState) {
        params.auditState = this.retrievalData.auditState;
      }
      if (this.retrievalData.registrationTime) {
        params.startDate = this.retrievalData.registrationTime[0];
        params.endDate = this.retrievalData.registrationTime[1];
      }
      this.doFetch({
        url: "/biz/projectUser/importApplyUserList",
        params,
        pageNum
      });
    },
    // 列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 确定
    determineData() {
      if (!this.multipleSelection.length) {
        this.$message.error("请至少选择一名学员");
        return
      } else {
        let arr = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push({
            userId: this.multipleSelection[i].userId,
            userName: this.multipleSelection[i].userName,
            idcard: this.multipleSelection[i].idcard,
            mobile: this.multipleSelection[i].mobile,
            workUnit: this.multipleSelection[i].workUnit,
          })
        }
        let params = {
          projectId: this.lastPageData.projectId,
          list: arr
        }
        this.$post("/biz/projectUser/addApplyUser", params)
          .then((res) => {
            this.multipleSelection = [];
            this.$refs.multipleTable.clearSelection();
            this.$message({
              message: "学员导入成功！",
              type: "success",
            });
            this.$router.back();
            
          })
          .catch(() => {
            return;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>